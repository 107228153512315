import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

const SinglePost = ({location, data: { previous, next, post } }) => {
  // console.log(
  //   `Previous: ${JSON.stringify(previous)}`,
  //   `Next: ${JSON.stringify(next)}`
  // )
  
  
  location = { ...location, pathname: "/blog/" }

  // console.log('location', location)

  let featuredImage = post?.featuredImage?.node
  return (
    <Layout location={location}>
      <Seo bodyClasses="blog" seo={post?.seo} />
      <section className="intro">
        {!!featuredImage && (
          <GatsbyImage
            image={featuredImage.localFile.childImageSharp.gatsbyImageData}
            alt={featuredImage.altText}
            loading="eager"
          />
        )}
        <div className="content white">
          <div className="container">
            <div className="row">
              <div className="col-md-9 col-lg-8 col-xl-7 copy">
                <blockquote>
                  <ul className="list-group list-group-horizontal">
                    {post.categories.nodes.map((category, i) => (
                      <li className="list-group-item border-0 p-0 me-1" key={i}>
                        <Link to={category.uri} className="text-uppercase">
                          {category.name}
                        </Link>
                        {i < post.categories.nodes.length - 1 && ","}
                      </li>
                    ))}
                  </ul>
                </blockquote>
                <h1>{post.title}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="content pb-0">
        <div className="container">
          <div className="row mb-3 d-none">
            <div className="col-md-12">
              <blockquote>
                <ul className="list-group list-group-horizontal">
                  {post.categories.nodes.map((category, i) => (
                    <li className="list-group-item border-0 p-0 me-1" key={i}>
                      <Link to={category.uri} className="link-primary">
                        {category.name}
                      </Link>
                      {i < post.categories.nodes.length - 1 && ","}
                    </li>
                  ))}
                </ul>
              </blockquote>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10">
              <div className="content">{parse(post.content)}</div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            {!!previous && (
              <div className="col">
                <Link
                  to={previous.uri}
                  title={previous.title}
                  className="link arrow-left"
                >
                  Previous blog
                </Link>
              </div>
            )}
            {!!next && (
              <div className="col text-end">
                <Link to={next.uri} title={next.title} className="link arrow">
                  Next blog
                </Link>
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SinglePost

export const postQuery = graphql`
  query BlogPostById2(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
      categories {
        nodes {
          name
          uri
        }
      }
      author {
        node {
          name
          nickname
          firstName
          lastName
          description
          email
          avatar {
            url
          }
        }
      }
      seo {
        ...WpYoastSEO
      }
      contentTypeName
      slug
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
